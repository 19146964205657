import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';
import HeroBanner from '@cof/graffiti-alley-spray-cans/molecules/HeroBanner';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import QuickCheckInfoBanner from '@cof/graffiti-alley-spray-cans/organisms/QuickCheck/QuickCheckInfoBanner';
import QuickCheckHeroBanner from '@cof/graffiti-alley-spray-cans/organisms/QuickCheck/QuickCheckHeroBanner';
import LearnAboutEligibility from '@cof/graffiti-alley-spray-cans/organisms/QuickCheck/components/LearnAboutEligibility';
import { NaturalSelectionSoloMode } from '@cof/natural-selection';

import { getCorrelationId } from '../lib/correlationId';
import { superScript } from '../lib/utils';
import { addQSIfNeeded } from '../lib/urls';
import LegalContent from '../components/ModalContent/HomePageLegalModalContent';
import QuickCheckLayout from '../components/QuickCheckLayout';
import SEOHelmet from '../components/SEO/SEOHelmet';
import SEO from '../components/SEO/SEO';
import newrelicPageAction from '../lib/newrelicSpa';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import cuid from 'cuid';
import { useConfig } from '../hooks/useConfig';

import QuickCheckCards from '../assets/images/quickcheck-cards.jpg';
import './quickcheck.scss';
import { LANGUAGES } from '../lib/constants';

const { NS, Control, Variation } = NaturalSelectionSoloMode;

export const generateCorrelationIdQS = (url) => {
  return addQSIfNeeded({
    url,
    key: 'corid',
    value: cuid(),
  });
};

const QuickCheckWelcomePage = injectIntl(({ intl }) => {
  const config = useConfig(intl.locale);
  const [href, setHref] = useState(generateCorrelationIdQS(config.qk));
  const onCheckNowBlur = () => {
    setHref(generateCorrelationIdQS(config.qk));
    newrelicPageAction('QuickCheckClick', {});
  };

  const supportsIntersectionObserver =
    typeof window !== 'undefined' ? window.IntersectionObserver || 'IntersectionObserver' in window : undefined;
  const componentRef = React.useRef(null);
  const entry = useIntersectionObserver(componentRef, {});
  const isVisible = !!entry?.isIntersecting;
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (config.isReady) {
      const [currentDomain, currentQS] = href.split('?');
      const [freshDomain] = config.qk.split('?');
      const shouldAddFrenchQS = intl.locale === 'fr' && currentQS.indexOf(`lang=${LANGUAGES.FRENCH_CANADA}`) === -1;
      if (currentDomain !== freshDomain || shouldAddFrenchQS) {
        const searchParams = new URLSearchParams(window.location.search);
        let updatedUrl = generateCorrelationIdQS(config.qk);
        if (shouldAddFrenchQS) {
          updatedUrl = generateCorrelationIdQS(
            addQSIfNeeded({ url: config.qk, key: 'lang', value: `${LANGUAGES.FRENCH_CANADA}` }),
          );
        }
        searchParams.forEach((value, key) => {
          updatedUrl = addQSIfNeeded({
            url: updatedUrl,
            key,
            value,
          });
        });
        setHref(() => updatedUrl);
      }
    }
  }, [config.isReady, config.qk, href, intl.locale]);

  return (
    <QuickCheckLayout>
      <SEOHelmet
        title={intl.formatMessage({ id: 'pages.quickcheck.page-title' })}
        description={intl.formatMessage({ id: 'pages.quickcheck.page-desc' })}
        lang={intl.locale}
      />
      <section className="page-content-wrapper quickcheck-welcome-page" data-testid="welcome-page-main-wrapper">
        <QuickCheckHeroBanner
          heroText={
            <FormattedMessage
              id="pages.quickcheck.qk-cta-control"
              values={{
                rball: (
                  <LearnAboutEligibility>
                    <sup className="dagger">®</sup>
                  </LearnAboutEligibility>
                ),
                star: <sup>*</sup>,
                MC: (
                  <LearnAboutEligibility>
                    <sup className="dagger">MC</sup>
                  </LearnAboutEligibility>
                ),
              }}
            />
          }
        >
          <div className="qc-hero-banner-button" ref={componentRef}>
            <Link to={href} className="action button see-cards-button" onBlur={() => onCheckNowBlur()}>
              <FormattedMessage id="pages.quickcheck.cta" />
            </Link>
          </div>
        </QuickCheckHeroBanner>
        <div className="qc-info-wrapper">
          <QuickCheckInfoBanner valueProp={<FormattedMessage id="pages.quickcheck.qk-value-prop-control" />} />
        </div>
        <div className="quickcheck-find-card page-content">
          <h2>
            <FormattedMessage id="pages.quickcheck.qk-cards-1" />
          </h2>
          <img className="quickcheck-cards" src={QuickCheckCards} alt="" />
          <p data-testid="card-section-paragraph">
            <FormattedMessage id="pages.quickcheck.qk-cards-2" values={{ star: superScript('star') }} />
          </p>
        </div>
        <HeroBanner backgroundContentClass="quickcheck-helping-canadians-hero-banner">
          <h2>
            <FormattedMessage id="pages.quickcheck.h2-1" />
          </h2>
          <p data-testid="banner-section-paragraph">
            <FormattedMessage id="pages.quickcheck.p1" values={{ star: superScript('star') }} />
          </p>
        </HeroBanner>
        <div id="quickcheck-page-legal" className="home-page-legal" data-for="home-page-legal">
          <ModalLink
            target={LegalContent}
            className="home-page-legal-link"
            title=" "
            linkIcon={false}
            intl={intl}
            size="large"
          >
            <FormattedMessage id="navfooter.legal.legal-updated" />
          </ModalLink>
        </div>
      </section>
      <NS experimentName="solo-mode-data-metrics" correlationId={getCorrelationId()}>
        <Control weight={0.5}>
          <span className="qk-www-solo-control"></span>
        </Control>
        <Variation name="qk-solo-test-variation" weight={0.5}>
          <span className="qk-www-not-solo-control"></span>
        </Variation>
      </NS>
      {(!supportsIntersectionObserver || isAnimating) && (
        <div className={`stickybar-wrapper ${!isVisible ? 'visible' : 'hidden'} ${isAnimating ? 'animating' : ''}`}>
          <div className="qk-sticky-button">
            <Link
              to={href}
              className="button large action apply-now-button qk-sticky-button-cta"
              onBlur={() => onCheckNowBlur()}
            >
              <FormattedMessage id="pages.quickcheck.cta" />
            </Link>
          </div>
        </div>
      )}
    </QuickCheckLayout>
  );
});

export default QuickCheckWelcomePage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.quickcheck.page-title" descriptionKey="pages.quickcheck.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
